var APP_NAME = 'app';
var app;
(function (app) {
    'use strict';
    angular.module(APP_NAME, [
        // angular translate
        // 'pascalprecht.translate',
        // dependant
        'ui.router',
        'ngAnimate',
        'ui.bootstrap',
        'app.templates',
        //services
        'app.core.AppRunConfig',
        'app.core.errorHandler',
        'app.core.cacheImages',
        'app.myLocalStorage',
        'app.dataservice',
        'app.rxData',
        //structure
        'app.core',
        'app.layout',
        // states/components
        'app.home',
        'app.about',
        'app.timeline',
        'app.media',
        'app.media.gallery',
        'app.contacts',
        'app.career',
        //directives
        'app.imageview',
        'app.statePreloader',
    ]);
})(app || (app = {}));
